<template>
  <div>
  </div>
</template>
<script >
import { mapActions } from 'vuex'
import AuthProvider from '@/resources/AuthProvider'
import CxOneProvider from '@/resources/CxOneProvider'
import jwt from 'jsonwebtoken'

const AuthService = new AuthProvider()
const CxOneService = new CxOneProvider()

export default {
  mounted () {
    const code = this.$route.query?.code
    this.signIn(code)
  },
  methods: {
    ...mapActions({
      setUser: 'User/setUser'
    }),
    async signIn (code) {
      try {
        const { data } = await AuthService.signInCXOneWithCode(code)
        if (data?.token) {
          const cxOneToken = data?.cxone?.accessToken
          this.setUser({
            ...data?.user,
            token: data?.token
          })
          this.$store.dispatch('User/setRefreshToken', { token: data?.cxone?.refreshToken })
          this.$store.dispatch('User/setCXONELogin', { isLogin: true })

          const expired = Math.floor(new Date().getTime() / 1000) + 2400

          this.$store.dispatch('User/setTokenExpired', { expired })
          this.$store.dispatch('User/setCXOneToken', { token: cxOneToken })
          
          //CXONE Skill
          if(cxOneToken) {
            const { icAgentId } = jwt?.decode(cxOneToken)
            const { data } = await CxOneService.skills(icAgentId)
            const agentSkillAssignments = data?.agentSkillAssignments
            if (Array.isArray(agentSkillAssignments)) {
              const skillData = agentSkillAssignments?.filter((item) => item?.mediaTypeId === 4)
              this.$store.dispatch('User/setCXOneSkill', { skill: skillData })
            }
          }
          this.$router.push({ name: 'dashboard' })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
